import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import NetworkDiscoveryAPI from '../apis/networkDiscoveryApi';
import { actions as AppActions, selectTableProps } from '../reducers/app';
import { actions as NetworkDiscoveryActions,
		 types as NetworkDiscoveryTypes } from '../reducers/networkDiscovery';
import { userCompanyId } from '../reducers/auth';
import errorHandler from '../utils/errorHandler';
import pageErrorHandler from '../utils/pageErrorHandler';

// CRUD Mac Address Saga Operations
function* getMacAddressList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(NetworkDiscoveryAPI.getMacAddress, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		if(!response.data)
			response.data = [];
		yield put(NetworkDiscoveryActions.getMacAddressSuccess(response.data, response.headers["x-pagination-count"]));
	} catch (error) {
		yield put(NetworkDiscoveryActions.getMacAddressError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addMacAddress(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.addMacAddress, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getMacAddressRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editMacAddress(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.editMacAddress, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getMacAddressRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteMacAddress(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.deleteMacAddress, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getMacAddressRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

// CRUD BSSID Saga Operations
function* getBssidList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(NetworkDiscoveryAPI.getBssid, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction, action.unmapped);
		if(!response.data)
			response.data = [];
		yield put(NetworkDiscoveryActions.getBssidSuccess(response.data, response.headers["x-pagination-count"]));
	} catch (error) {
		yield put(NetworkDiscoveryActions.getBssidError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addBssid(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.addBssid, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getBssidRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editBssid(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.editBssid, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getBssidRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteBssid(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.deleteBssid, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getBssidRequest(tableProps.page, tableProps.pageSize, action.data.parentId, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getIpRangeList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const storedIsPublicIpRange = JSON.parse(sessionStorage.getItem('isPublicIpRange'));
		if (action.isPublic === null || action.isPublic === undefined){
			action.isPublic = storedIsPublicIpRange;
		}
		let response;
		if (action.isPublic) {
			response = yield call(NetworkDiscoveryAPI.getPublicIpRange, companyId, action.page, action.pageSize, action.sortBy, action.direction, action.searchTerm);
		} else {
			response = yield call(NetworkDiscoveryAPI.getIpRange, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction);
		}
		if(!response.data)
			response.data = [];
		yield put(NetworkDiscoveryActions.getIpRangeSuccess(response.data, response.headers["x-pagination-count"]));
	} catch (error) {
		yield put(NetworkDiscoveryActions.getIpRangeError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addIpRange(action) {
	try {
		yield put(AppActions.setModalLoading());
		const companyId = yield select(userCompanyId());
		const storedIsPublicIpRange = JSON.parse(sessionStorage.getItem('isPublicIpRange'));
		const tableProps = yield select(selectTableProps());
		
		if (storedIsPublicIpRange) {
			yield call(NetworkDiscoveryAPI.addPublicIpRange, action.data, companyId);
		} else {
			yield call(NetworkDiscoveryAPI.addIpRange, action.data, companyId);
		}
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getIpRangeRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editIpRange(action) {
	try {
		const tableProps = yield select(selectTableProps());
		
		yield put(AppActions.setModalLoading());
		const companyId = yield select(userCompanyId());
		const storedIsPublicIpRange = JSON.parse(sessionStorage.getItem('isPublicIpRange'));
		
		if (storedIsPublicIpRange) {
			yield call(NetworkDiscoveryAPI.editPublicIpRange, action.data, companyId);
		} else {
			yield call(NetworkDiscoveryAPI.editIpRange, action.data, companyId);
		}
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getIpRangeRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteIpRange(action) {
	try {
		yield put(AppActions.setModalLoading());
		const companyId = yield select(userCompanyId());
		const storedIsPublicIpRange = JSON.parse(sessionStorage.getItem('isPublicIpRange'));
		const tableProps = yield select(selectTableProps());
		
		if (storedIsPublicIpRange) {
			yield call(NetworkDiscoveryAPI.deletePublicIpRange, action.data, companyId);
		} else {
			yield call(NetworkDiscoveryAPI.deleteIpRange, action.data, companyId);
		}
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getIpRangeRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getLldpList(action) {
	try {
		const companyId = yield select(userCompanyId());
		const response = yield call(NetworkDiscoveryAPI.getLldp, companyId, action.page, action.pageSize, action.searchTerm, action.sortBy, action.direction, action.unmapped);
		if(!response.data)
			response.data = [];
		yield put(NetworkDiscoveryActions.getLldpSuccess(response.data, response.headers["x-pagination-count"]));
	} catch (error) {
		yield put(NetworkDiscoveryActions.getLldpError());
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addLldp(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.addLldp, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getLldpRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editLldp(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.editLldp, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getLldpRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deleteLldp(action) {
	try {
		const companyId = yield select(userCompanyId());
		const tableProps = yield select(selectTableProps());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.deleteLldp, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getLldpRequest(tableProps.page, tableProps.pageSize, null, tableProps.sortBy, tableProps.direction));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* getPortList(action) {
	try {
		const response = yield call(NetworkDiscoveryAPI.getPort, action.lldpId, action.sortBy, action.direction);
		if(!response.data)
			response.data = [];
		yield put(NetworkDiscoveryActions.getPortSuccess(response.data, action.lldpId));
	} catch (error) {
		yield put(NetworkDiscoveryActions.getPortError(action.lldpId));
		yield put(AppActions.setPageError(pageErrorHandler(error)));
		console.log(error);
	}
}

function* addPort(action) {
	try {
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.addPort, action.data);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getPortRequest(action.data.chassisId, action.data.sortBy.id, action.data.sortBy.desc ? 'DESC': 'ASC'));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* editPort(action) {
	try {
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.editPort, action.data);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getPortRequest(action.data.networkSwitchId, action.data.sortBy.id, action.data.sortBy.desc ? 'DESC': 'ASC'));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* deletePort(action) {
	try {
		const companyId = yield select(userCompanyId());
		yield put(AppActions.setModalLoading());
		yield call(NetworkDiscoveryAPI.deletePort, action.data, companyId);
		yield put(AppActions.setModalClose());
		yield put(NetworkDiscoveryActions.getPortRequest(action.parentId, action.sortBy.id, action.sortBy.desc ? 'DESC': 'ASC'));
	} catch (error) {
		console.log(error);
		yield put(AppActions.setModalError(errorHandler(error)));
	}
}

function* rootLocationsSaga() {
	yield all([
		yield takeLatest(NetworkDiscoveryTypes.GET_MAC_ADDRESS_REQUEST, getMacAddressList),
		yield takeLatest(NetworkDiscoveryTypes.ADD_MAC_ADDRESS, addMacAddress),
		yield takeLatest(NetworkDiscoveryTypes.EDIT_MAC_ADDRESS, editMacAddress),
		yield takeLatest(NetworkDiscoveryTypes.DELETE_MAC_ADDRESS, deleteMacAddress),
		yield takeLatest(NetworkDiscoveryTypes.GET_BSSID_REQUEST, getBssidList),
		yield takeLatest(NetworkDiscoveryTypes.ADD_BSSID, addBssid),
		yield takeLatest(NetworkDiscoveryTypes.EDIT_BSSID, editBssid),
		yield takeLatest(NetworkDiscoveryTypes.DELETE_BSSID, deleteBssid),
		yield takeLatest(NetworkDiscoveryTypes.GET_IP_RANGE_REQUEST, getIpRangeList),
		yield takeLatest(NetworkDiscoveryTypes.ADD_IP_RANGE, addIpRange),
		yield takeLatest(NetworkDiscoveryTypes.EDIT_IP_RANGE, editIpRange),
		yield takeLatest(NetworkDiscoveryTypes.DELETE_IP_RANGE, deleteIpRange),
		yield takeLatest(NetworkDiscoveryTypes.GET_LLDP_REQUEST, getLldpList),
		yield takeLatest(NetworkDiscoveryTypes.ADD_LLDP, addLldp),
		yield takeLatest(NetworkDiscoveryTypes.EDIT_LLDP, editLldp),
		yield takeLatest(NetworkDiscoveryTypes.DELETE_LLDP, deleteLldp),
		yield takeLatest(NetworkDiscoveryTypes.GET_PORT_REQUEST, getPortList),
		yield takeLatest(NetworkDiscoveryTypes.ADD_PORT, addPort),
		yield takeLatest(NetworkDiscoveryTypes.EDIT_PORT, editPort),
		yield takeLatest(NetworkDiscoveryTypes.DELETE_PORT, deletePort)
	]);
}

export default rootLocationsSaga;
