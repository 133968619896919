import React, {useState, useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from '../../components/HasAccess/HasAccess';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import IpRangeListTable from '../../containers/IpRangeListTable/ipRangeListTable';

import { actions as IpRangeActions, makeSelectIpRangeList, makeSelectIpRangeListLoading, makeSelectPublicIpRange } from '../../reducers/networkDiscovery';

import { types as ipRangeTypes } from '../../reducers/networkDiscovery';
import { actions as AppActions, selectPageSize, selectCurrentPage } from '../../reducers/app';
import SearchField from "../../utils/searchField";
import searchFieldRegex from "../../utils/searchFieldRegex";
import { Link } from 'react-router-dom';


const IpRangeList = props => {
    //const storedIsPublicIpRange = JSON.parse(sessionStorage.getItem('isPublicIpRange'));

    const [isPublic, setIsPublic] = useState(props.isPublic ? props.isPublic : false);
    const [searchValue, setSearchValue] = useState('');
    
    const modalHandleOpen = () => {
        if (isPublic){
            sessionStorage.setItem('isPublicIpRange', true);
            props.setCurrentModal(ipRangeTypes.ADD_IP_RANGE);
        } else {
            sessionStorage.setItem('isPublicIpRange', false);
            props.setCurrentModal(ipRangeTypes.ADD_IP_RANGE);
        }
    }

    const handleChange = event => {
        setIsPublic(event.target.checked);
    };

    const handleInputSearchChange = (event) => {
        const inputValue = event.target.value;
        const isMatch = searchFieldRegex(inputValue)

        if (isMatch) {
            setSearchValue(inputValue);
        }
    };
    return (
        <div>
            {(!isPublic ? 
            <div display="block" padding-bottom="15px"><strong>Important:</strong> To properly detect users that are located onsite vs. offsite/remote, you need to make sure your  <Link to="/organization-management">Public IP Ranges</Link> are also configured. <br/><br/></div>
            : <></>
            )}
            <Grid style={{ marginBottom: '-20px' }} container direction="row" justify="flex-start" alignItems="center">      
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <SearchField
                            value={searchValue}
                            onChange={handleInputSearchChange}
                        />
                    </Grid>
                    {isPublic && searchValue.length === 0? (
                    <Grid item>
                        <HasAccess
                            permissions={["ADD_NETWORK_DISCOVERY"]}
                        >
                            <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                                Add IP Range
                            </Button>
                            
                        </HasAccess>
                    </Grid>
                
            ) :  (
            <>
            <Grid item>
                        <HasAccess
                            permissions={["ADD_NETWORK_DISCOVERY"]}
                        >
                             {searchValue.length === 0 ?
                            <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                                Add IP Range Mapping
                            </Button>
                            :
                             ""}
                        </HasAccess>
                    </Grid></> )}
                </Grid>
        </Grid>
        <br />

            <br />
            <IpRangeListTable isPublic={isPublic}
                              ipRangeList={props.ipRangeList}
                              searchValue={searchValue}
                              setCurrentModal={props.setCurrentModal}
                              loading={props.loading} />
        </div>
    );
}

IpRangeList.propTypes = {
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    ipRangeList: makeSelectIpRangeList(),
    loading: makeSelectIpRangeListLoading(),
    pageSize: selectPageSize(),
    currentPage: selectCurrentPage()
});

const mapDispatchToProps = dispatch => {
    return {
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(IpRangeList);
