/**
 * Attempt to follow 'Ducks' Modular redux pattern
 * https://github.com/erikras/ducks-modular-redux
 *
 * Needed this reducer in App container for react-boilerplate to inject global reducer
 */

import { fromJS, List } from 'immutable';
import { createSelector } from 'reselect';
import { LOGOUT } from './auth';

const APP = 'APP/';
export const types = {
	LOGIN_REQUEST: APP + 'LOGIN_REQUEST',
	LOGIN_SUCCESS: APP + 'LOGIN_SUCCESS',
	LOGIN_ERROR: APP + 'LOGIN_ERROR',
	MODAL_CURRENT: APP + 'MODAL_CURRENT',
	MODAL_ERROR: APP + 'MODAL_ERROR',
	MODAL_ERROR_CLEAR: APP + 'MODAL_ERROR_CLEAR',
	MODAL_LOADING: APP + 'MODAL_LOADING',
	MODAL_CLOSE: APP + 'MODAL_CLOSE',
	SAVE_PROFILE: APP + 'SAVE_PROFILE',
	SAVE_PROFILE_SUCCESS: APP + 'SAVE_PROFILE_SUCCESS',
	PROFILE_ERROR: APP + 'PROFILE_ERROR',
	PROFILE_ERROR_CLEAR: APP + 'PROFILE_ERROR_CLEAR',
	LOGOUT: APP + 'LOGOUT',
	CURRENT_COMPANY_LEVEL_SYSTEM: 'SYSTEM',
	CURRENT_COMPANY_LEVEL_TOP_LEVEL: 'TOP_LEVEL',
	CURRENT_COMPANY_LEVEL_SUBTENANT: 'SUBTENANT',
	UPDATE_SUCCESS_SNACKBAR: APP + 'UPDATE_SUCCESS_SNACKBAR',
	SHIFT_SUCCESS_SNACKBAR_QUEUE: APP + 'SHIFT_SUCCESS_SNACKBAR_QUEUE',
    MODAL_CONFIRM: APP + 'MODAL_CONFIRM',
	SET_PAGE_TITLE: APP + 'SET_PAGE_TITLE',
	SET_PAGE_TITLE_ID: APP + 'SET_PAGE_TITLE_ID',
	PAGE_ERROR: APP + 'PAGE_ERROR',
	ORG_HAS_LICENSE: APP + 'ORG_HAS_LICENSE',
	CIRRUS_SKINS: APP + 'CIRRUS_SKINS',
	CIRRUS_VERSION: APP + 'CIRRUS_VERSION',
	CIRRUS_ENVIRONMENT: APP + 'CIRRUS_ENVIRONMENT',
	SET_PAGE_SIZE: APP + 'SET_PAGE_SIZE',
	SET_NESTED_TABLE_PAGE_SIZE: APP + 'SET_NESTED_TABLE_PAGE_SIZE',
	SET_CURRENT_PAGE: APP + 'SET_CURRENT_PAGE',
	SET_NESTED_TABLE_CURRENT_PAGE: APP + 'SET_NESTED_TABLE_CURRENT_PAGE',
	SET_SEARCH_TERM: APP + 'SET_SEARCH_TERM',
	MY_ACCOUNT_FORM_SUBMITTED: APP + 'MY_ACCOUNT_FORM_SUBMITTED',
	MY_ACCOUNT_MODAL: APP + 'MY_ACCOUNT_MODAL',
	SET_COMPANY_FEATURES: APP + 'SET_COMPANY_FEATURES',
	SET_TABLE_PROPS: APP + 'SET_TABLE_PROPS',
	ADD_API_KEY_SUCCESS_MODAL: APP + 'ADD_API_KEY_SUCCESS_MODAL'
};

// The initial state of the App
export const initialState = fromJS({
	modal: {
		open: false,
		current: '',
		loading: false,
		data: null,
		error: {
			display: false,
			message: '',
		},
		errorOptions: null,
        modalConfirm: false
	},
	profile: {
		saved: false,
		error: {
			display: false,
			message: ''
		}
	},
	successSnackbar: List(), // ImmutableJS Stack works like traditional Queue
	pageTitle: '',
	pageTitleId: '',
	pageSize: 25,
	nestedTablePageSize: 25,
	currentPage: '',
	nestedTableCurrentPage: '',
	searchTerm: '',
	page: {
		error: {
			display: false,
			message: '',
		},
	},
	orgHasLicense: false,
	cirrusSkins: '',
	cirrusVersion: '',
	cirrusEnvironment: '',
	myAccountFormSubmitted: false,
	companyFeatures: [],
	tableProps: null
});

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
	switch (action.type) {
		case types.LOGIN_ERROR:
			return state.setIn(['login', 'loading'], false)
				.setIn(['login', 'error', 'display'], true)
				.setIn(['login', 'error', 'message'], action.error.message);
		case types.MODAL_CURRENT:
			return state.setIn(['modal', 'current'], action.name)
				.setIn(['modal', 'open'], true)
				.setIn(['modal', 'data'], action.data);
		case types.MODAL_LOADING:
			return state.setIn(['modal', 'loading'], !state.getIn(['modal', 'loading']));
		case types.MODAL_ERROR:
			return state.setIn(['modal', 'error', 'message'], action.error)
				.setIn(['modal', 'error', 'data'], action.errorData)
				.setIn(['modal', 'error', 'display'], true)
				.setIn(['modal', 'loading'], false)
				.setIn(['modal', 'errorOptions'], action.errorOptions);
		case types.MODAL_ERROR_CLEAR:
			return state.setIn(['modal', 'error', 'display'], false);
		case types.MODAL_CLOSE:
			return state.mergeDeepIn(['modal'], fromJS({
				open: false,
				current: '',
				loading: false,
				data: null,
				error: {
					display: false,
					message: '',
				},
				errorOptions: null,
				modalConfirm: false
			}));
		case types.PAGE_ERROR:
			return state.setIn(['page', 'error', 'message'], action.error)
				.setIn(['page', 'error', 'display'], true)
				.setIn(['page', 'loading'], false);
		case types.SAVE_PROFILE:
			return state.mergeDeepIn(['user'], fromJS({
				firstName: action.data.firstName,
				lastName: action.data.lastName
			})).setIn(['profile', 'saved'], true);
		case types.PROFILE_ERROR_CLEAR:
			return state.setIn(['profile', 'error', 'display'], false);
		case types.PROFILE_ERROR:
			return state.setIn(['profile', 'error', 'display'], true)
				.setIn(['profile', 'error', 'message'], action.error);
		case types.UPDATE_SUCCESS_SNACKBAR:
			return state.set('successSnackbar', state.get('successSnackbar').push({message: action.message, note: action.note}));
		case types.SHIFT_SUCCESS_SNACKBAR_QUEUE:
			return state.set('successSnackbar', state.get('successSnackbar').shift());
        case types.MODAL_CONFIRM:
			return state.setIn(['modal', 'modalConfirm'], action.data);
		case types.SET_PAGE_TITLE:
			return state.set('pageTitle', action.data);
		case types.SET_PAGE_TITLE_ID:
			return state.set('pageTitleId', action.data);
		case types.SET_PAGE_SIZE:
			return state.set('pageSize', action.data);
		case types.SET_NESTED_TABLE_PAGE_SIZE:
			return state.set('nestedTablePageSize', action.data);
		case types.SET_CURRENT_PAGE:
			return state.set('currentPage', action.data);
		case types.SET_NESTED_TABLE_CURRENT_PAGE:
			return state.set('nestedTableCurrentPage', action.data);
		case types.SET_SEARCH_TERM:
			return state.set('searchTerm', action.data);
		case types.SET_TABLE_PROPS:
			return state.set('tableProps', action.data);
		case types.ORG_HAS_LICENSE:
			return state.set('orgHasLicense', action.data);
		case types.CIRRUS_SKINS:
			return state.set('cirrusSkins', action.data);
		case types.CIRRUS_VERSION:
			return state.set('cirrusVersion', action.data);
		case types.CIRRUS_ENVIRONMENT:
			return state.set('cirrusEnvironment', action.data);
		case types.MY_ACCOUNT_FORM_SUBMITTED:
			return state.set('myAccountFormSubmitted', action.data);
		case LOGOUT:
			return state.set('page', fromJS(
				{
					page: {
					error: {
						display: false,
						message: '',
					}
				}
			}))
		case types.SET_COMPANY_FEATURES:
			return state.set('companyFeatures', action.companyFeatures);
		default:
			return state;
	}
}

// Action creators
export const actions = {
	setCurrentModal: (modalName, data) => ({
		type: types.MODAL_CURRENT,
		name: modalName,
		data: data
	}),
	setModalLoading: () => ({
		type: types.MODAL_LOADING
	}),
	setModalError: (error, errorData) => ({
		type: types.MODAL_ERROR,
		error: error,
		errorData: errorData
	}),
	setModalErrorOptions: (error) => ({
		type: types.MODAL_ERROR,
		errorOptions: error
	}),
	setModalErrorClear: () => ({
		type: types.MODAL_ERROR_CLEAR
	}),
	setModalClose: () => ({
		type: types.MODAL_CLOSE
	}),
	setPageError: (error) => ({
		type: types.PAGE_ERROR,
		error: error
	}),
	submitProfileRequest: (profile) => ({
		type: types.SAVE_PROFILE,
		data: profile
	}),
	submitProfileSuccess: (userTO) => ({
		type: types.SAVE_PROFILE_SUCCESS,
		data: userTO
	}),
	submitProfileError: (error) => ({
		type: types.PROFILE_ERROR,
		error: error
	}),
	setProfileErrorClear: () => ({
		type: types.PROFILE_ERROR_CLEAR
	}),
	updateSuccessSnackbar: (message, note) => ({
		type: types.UPDATE_SUCCESS_SNACKBAR,
		message,
		note
	}),
	shiftSuccessSnackbarQueue: () => ({
		type: types.SHIFT_SUCCESS_SNACKBAR_QUEUE
	}),
    setModalConfirm: (flag) => ({
        type: types.MODAL_CONFIRM,
        data: flag
	}),
	setPageTitle: (data) => ({
		type: types.SET_PAGE_TITLE,
		data
	}),
	setPageTitleId: (data) => ({
		type: types.SET_PAGE_TITLE_ID,
		data
	}),
	setPageSize: (data) => ({
		type: types.SET_PAGE_SIZE,
		data
	}),
	setNestedTablePageSize: (data) => ({
		type: types.SET_NESTED_TABLE_PAGE_SIZE,
		data
	}),
	setCurrentPage: (data) => ({
		type: types.SET_CURRENT_PAGE,
		data
	}),
	setNestedTableCurrentPage: (data) => ({
		type: types.SET_NESTED_TABLE_CURRENT_PAGE,
		data
	}),
	setSearchTerm: (data) => ({
		type: types.SET_SEARCH_TERM,
		data
	}),
	setTableProps: (data) => ({
		type: types.SET_TABLE_PROPS,
		data
	}),
	orgHasLicense: (data) => ({
		type: types.ORG_HAS_LICENSE,
		data
	}),
	cirrusSkins: (data) => ({
		type: types.CIRRUS_SKINS,
		data
	}),
	cirrusVersion: (data) => ({
		type: types.CIRRUS_VERSION,
		data
	}),
	cirrusEnvironment: (data) => ({
		type: types.CIRRUS_ENVIRONMENT,
		data
	}),
	setMyAccountFormSubmitted: (data) => ({
		type: types.MY_ACCOUNT_FORM_SUBMITTED,
		data
	}),
	setCompanyFeatures: (companyFeatures) => ({
		type: types.SET_COMPANY_FEATURES,
		companyFeatures
	})
};

// Login Selector
const selectLogin = state => state.getIn(['global', 'login'], initialState);

export const selectLoginLoading = () =>
	createSelector(selectLogin, loginState => loginState.get('loading'));

export const selectLoginErrorDisplay = () =>
	createSelector(selectLogin, loginState => loginState.getIn(['error', 'display']));

export const selectLoginErrorMessage = () =>
	createSelector(selectLogin, loginState => loginState.getIn(['error', 'message']));

// My Account (Profile) Selector
const selectProfile = state => state.getIn(['global', 'profile'], initialState);

export const selectProfileErrorDisplay = () =>
	createSelector(selectProfile, profile => profile.getIn(['error', 'display']));

export const selectProfileErrorMessage = () =>
	createSelector(selectProfile, profile => profile.getIn(['error', 'message']));


// Modal Selectors
const selectModal = state => state.getIn(['global', 'modal'], initialState);
export const modalOpen = () =>
	createSelector(selectModal, modalState => modalState.get('open'));

export const modalLoading = () =>
	createSelector(selectModal, modalState => modalState.get('loading'));

export const modalData = () =>
	createSelector(selectModal, modalState => modalState.get('data'));

export const modalErrorDisplay = () =>
	createSelector(selectModal, modalState => modalState.getIn(['error', 'display']));

export const modalErrorMessage = () =>
	createSelector(selectModal, modalState => modalState.getIn(['error', 'message']));

export const modalErrorOptions = () =>
	createSelector(selectModal, modalState => modalState.getIn(['errorOptions']));

export const modalCurrent = () =>
	createSelector(selectModal, modalState => modalState.get('current'));

export const modalConfirm = () =>
	createSelector(selectModal, modalState => modalState.get('modalConfirm'));

// Success Snackbar Selectors
export const global = state => state.get('global', initialState);

export const selectSuccessSnackbarQueue = () => 
	createSelector(global, globalState => globalState.get('successSnackbar') )

// Page Title Selector
export const selectPageTitle = () =>
	createSelector(global, pageTitleState => pageTitleState.get('pageTitle'));

// Page Title Selector
export const selectPageTitleId = () =>
	createSelector(global, pageTitleIdState => pageTitleIdState.get('pageTitleId'));

// Page Size Selector
export const selectPageSize = () =>
	createSelector(global, pageSizeState => pageSizeState.get('pageSize'));

export const selectNestedTablePageSize = () =>
	createSelector(global, nestedTablePageSizeState => nestedTablePageSizeState.get('nestedTablePageSize'));

// Current Page Selector
export const selectCurrentPage = () =>
	createSelector(global, currentPageState => currentPageState.get('currentPage'));

export const selectNestedTableCurrentPage = () =>
	createSelector(global, nestedTableCurrentPageState => nestedTableCurrentPageState.get('nestedTableCurrentPage'));

// Search Term Selector
export const selectSearchTerm = () =>
	createSelector(global, searchTermState => searchTermState.get('searchTerm'));

// Table Props Selector
export const selectTableProps = () =>
	createSelector(global, tablePropsState => tablePropsState.get('tableProps'));

// Page Errors Selectors	
const selectPage = state => state.getIn(['global', 'page'], initialState);

export const pageErrorDisplay = () =>
	createSelector(selectPage, pageState => pageState.getIn(['error', 'display']));

export const pageErrorMessage = () =>
	createSelector(selectPage, pageState => pageState.getIn(['error', 'message']));

// Org Has License Selector 
export const orgHasLicense = () =>
	createSelector(global, orgHasLicenseState => orgHasLicenseState.get('orgHasLicense'));

export const cirrusSkins = () =>
	createSelector(global, cirrusSkinsState => cirrusSkinsState.get('cirrusSkins'));

export const cirrusVersion = () =>
	createSelector(global, cirrusVersionState => cirrusVersionState.get('cirrusVersion'));

export const cirrusEnvironment = () =>
	createSelector(global, cirrusEnvironmentState => cirrusEnvironmentState.get('cirrusEnvironment'));

// My Account Form Submitted
export const selectMyAccountFormSubmitted = () =>
	createSelector(global, myAccountFormSubmittedState => myAccountFormSubmittedState.get('myAccountFormSubmitted'));

export const selectCerEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('CER'));

export const selectAlternateIdEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('ALT_DEVICE_USER_ID'));

export const selectInformaCastEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('INFORMACAST'));

export const selectLocationAlternateIdEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('ALT_LOCATION_ID'));

export const selectEmergencyCallAssistEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('EMERGENCY_CALL_ASSIST'));

export const selectMyE911UserWelcomeEmailEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('MYE911_USER_WELCOME_EMAIL'));

export const selectUserWelcomeEmailEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('USER_WELCOME_EMAIL'));

export const selectMyE911ClientSoftwareUpdatesEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('MYE911_CLIENT_SOFTWARE_UPDATES'));

export const selectMyE911LastKnownLocationEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('MYE911_DISABLE_INFORMATIONAL_NOTIFICATIONS'));
	
export const selectUseDIDforFallBackRoutingOfHELDEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('DID_ROUTING_FALLBACK'));

export const selectAllowStickyIPNetworkElementMappingToLocations = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('USER_IP_MAPPING'));

export const selectHorizonPrimeEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('HORIZON_PRIME'));

export const selectLLDPChassisAutoCreationEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('LLDP_CHASSIS_AUTO_CREATION'));

export const selectLLDPPortAutoCreationEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('LLDP_PORT_AUTO_CREATION'));

export const selectCorpBSSIDAutoCreationEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('CORP_BSSID_AUTO_CREATION'));

export const selectBypassMSTeamsManualLocationVerificationEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('ALLOW_PIDFLO_METHOD_MANUAL'));

export const selectEnforceDeviceUidUniquenessEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes('DEVICE_USER_ID_UNIQUENESS'));

export const selectCleanUpInactiveDevicesEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes("USER_DEVICE_PURGE_30") || globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_60" ) || globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_90") || globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_180") || globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_365"));

export const selectCleanUpInactiveHeldEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes("HELD_DEVICE_PURGE_30") || globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_60" ) || globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_90") || globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_180") || globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_365"));

export const selectCleanUpUserPurgeEnabled = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes("USER_PURGE"));

export const selectDaysInactiveUsersSelect = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes("USER_DEVICE_PURGE_30") ? "USER_DEVICE_PURGE_30" : globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_60" ) ? 'USER_DEVICE_PURGE_60' : globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_90") ? 'USER_DEVICE_PURGE_90' : globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_180") ? 'USER_DEVICE_PURGE_180' : globalState.get('companyFeatures').includes( "USER_DEVICE_PURGE_365") ? 'USER_DEVICE_PURGE_365' : '');
	
export const selectDaysInactiveHeldSelect = () =>
	createSelector(global, (globalState) => globalState.get('companyFeatures').includes("HELD_DEVICE_PURGE_30") ? "HELD_DEVICE_PURGE_30" : globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_60" ) ? 'HELD_DEVICE_PURGE_60' : globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_90") ? 'HELD_DEVICE_PURGE_90' : globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_180") ? 'HELD_DEVICE_PURGE_180' : globalState.get('companyFeatures').includes( "HELD_DEVICE_PURGE_365") ? 'HELD_DEVICE_PURGE_365' : '');