import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from '../../components/HasAccess/HasAccess';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';

import MacListTable from '../../containers/MacListTable/macListTable';

import { actions as MacAddressActions, makeSelectMacAddressList, makeSelectMacAddressListLoading } from '../../reducers/networkDiscovery';

import { types as locationsTypes } from '../../reducers/networkDiscovery';
import { actions as AppActions } from '../../reducers/app';
import { debounce } from 'lodash';

const initialSortColumns = [
    {
        id: 'location',
        desc:false
    }
]

function MacList(props) {
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [pageSize, setPageSize] = useState(25);
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    function modalHandleOpen(){
        props.setCurrentModal(locationsTypes.ADD_MAC_ADDRESS);
    }

    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const isMatch = searchFieldRegex(inputValue);

        if (isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC'    
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction) => {
            if (inputValue.length > 0) {
                setPage(0);
                props.fetchMacList(1, pageSize, inputValue, sortBy, direction);
            } else if(inputValue.length === 0){
                setPage(0);
                props.fetchMacList(1, pageSize, '', sortBy, direction)
            }
        }, 500)
    , []);

        return (
            <div>
                <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                        <SearchField 
                            value={searchValue}
                            onChange={handleInputSearchChange}
                        />
                    </Grid>
                    <Grid item>
                    <HasAccess
                        permissions={["ADD_NETWORK_DISCOVERY"]}
                    >
                        {   searchValue.length === 0 &&
                        <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                            Add MAC Address Mapping
                        </Button> 
                        }
                    </HasAccess>
                    </Grid>
                </Grid>
                <br />
                <MacListTable 
                macAddressList={props.macAddressList} 
                setCurrentModal={props.setCurrentModal} 
                loading={props.loading}
                setPage={setPage}
                page={page}
                sortColumns={sortColumns}
                setSortColumns={setSortColumns}
                pageSize={pageSize}
                setPageSize={setPageSize}
                currentSortColumn={currentSortColumn}
                setCurrentSortColumn={setCurrentSortColumn}
                searchValue={searchValue}/>
            </div>    
        );
    
}

MacList.propTypes = {
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    macAddressList: makeSelectMacAddressList(),
    loading: makeSelectMacAddressListLoading(),
});

const mapDispatchToProps = dispatch => {
    return {
        fetchMacList: (page, pageSize, searchTerm, sortBy, direction) => dispatch(MacAddressActions.getMacAddressRequest(page, pageSize, searchTerm, sortBy, direction)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(MacList);
