import React, { useState, useEffect } from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { Formik } from 'formik';
import * as Yup from "yup";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import SingleSignOnApi from '../../../apis/singleSignOnApi';
import { actions as OrganizationManagementAction,
                    makeSelectCompanySsoSettings,
                    makeSelectSsoLoading } from '../../../reducers/organizationManagement';

function SingleSignOnSettings(formikProps) {
    const {
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        submitStatus,
        errorMessage,
        dataLoading,
        touched,
        errors,
        setFieldValue,
        validateForm,
        setFormValues,
        deleteSsoCredentials,
        getSsoCredentials,
        currentSsoCredentials,
        resetForm,
        success,
        setSuccess,
        setSubmitStatus,
        setErrorMessage,
        setErrors,
        disableInput
    } = formikProps;

    function handleSecurityHashAlgorithm(e) {
        const inputValue = e.target.value
        setFieldValue('securityHashAlgorithm', inputValue);
    }
    
    function handleIdentityLocation(e) {
        const inputValue = e.target.value
        setFieldValue('identityLocation', inputValue);
    }
    
    function handleBindingType(e) {
        const inputValue = e.target.value
        setFieldValue('bindingType', inputValue);
    }

    function handleFiles(e) {
        if (e.target.files.length > 0) {
            setFieldValue('fileName', e.target.files[0].name)
            setFieldValue('file', e.target.files[0]);
        }
    }

    function handleDelete() {
        deleteSsoCredentials(formikProps.props.company.id);
        resetForm();
        setSuccess(false);
        setSubmitStatus('');
    }

    useEffect(() => {
        if (values.fileName.length > 0) {
            setTimeout(() => {
                validateForm();
            }, [500]);
        }
    }, [values.fileName]);
    
    useEffect(() => {
        setFormValues(values);
    }, [values]);

    useEffect(() => {
        getSsoCredentials(formikProps.props.company.id)
    }, []);

    useEffect(() => {
        if (currentSsoCredentials.apiName !== undefined) {
            setFieldValue('apiName', currentSsoCredentials.apiName);
        } else {
            resetForm()
            setErrors({})
        }
    }, [currentSsoCredentials.apiName]);
    
    useEffect(() => {
        if (currentSsoCredentials.metadataName) {
            setFieldValue('fileName', currentSsoCredentials.metadataName);
        }
    }, [currentSsoCredentials.metadataName]);

    useEffect(() => {
        if (currentSsoCredentials.identityLocation) {
            setFieldValue('identityLocation', currentSsoCredentials.identityLocation);
        }
    }, [currentSsoCredentials.identityLocation]);

    useEffect(() => {
        if (currentSsoCredentials.securityHashAlgorithm) {
            setFieldValue('securityHashAlgorithm', currentSsoCredentials.securityHashAlgorithm);
        }
    }, [currentSsoCredentials.securityHashAlgorithm]);

        return (
        <form onSubmit={handleSubmit} autoComplete="off" className="sso-form" noValidate="novalidate">
            {dataLoading && <LinearProgress/>}
            <Grid container justify="center" direction="row" alignItems="flex-start" style={{margin: '20px 20px 30px'}}>
                <Grid item>
                    <TextField
                        style={{ width: 350 }}
                        id="apiName"
                        label="*API Name"
                        name="apiName"
                        onChange={handleChange}
                        value={values.apiName}
                        onBlur={handleBlur}
                        fullWidth={true}
                        disabled={(dataLoading || !disableInput)}
                        error={(touched.apiName && Boolean(errors.apiName)) || (errors.apiName && Boolean(errors.apiName.includes('max')))} 
                    />
                    <div id="apiName-helper-text" className="error-prompt">{(touched.apiName && Boolean(errors.apiName)) || (errors.apiName && Boolean(errors.apiName.includes('max'))) ? errors.apiName : ''}</div>
                    <br/>
                    <br/>
                    <FormLabel id="identityProviderMetadata" htmlFor="input" style={{padding: '0px 10px 15px 0px', display: 'inline-block'}}>*Identity Provider Metadata:</FormLabel>
                    <br/>
                    <div className="upload-wrapper">
                        <div className="upload-btn-wrapper">
                            <label style={{ opacity: (dataLoading || !disableInput) ? 0.5 : 1, cursor: (dataLoading || !disableInput) ? 'default' : 'pointer' }} className="upload-button" tabIndex={disableInput ? 0 : -1} 
                                onKeyDown={e => {
                                    if (e.key === 'Enter' || e.key === ' ') {
                                        const fileUploadElm = document.querySelector('input[type=file]');

                                        if (fileUploadElm) {
                                            fileUploadElm.click();
                                        }
                                    }
                                }}
                            >
                                Upload XML File
                                <input 
                                    disabled={(dataLoading || !disableInput)} 
                                    type="file" id="input" 
                                    onChange={handleFiles} 
                                />
                            </label>

                        </div>
                        <div>{values.fileName}</div>
                    </div>
                    { (errors.fileName && touched.fileName) && <div id="xml-error-message" className="error-prompt">{errors.fileName}</div>}
                    <br/>
                    <br/>
                    <FormLabel id="securityHashAlgorithm" style={{padding: '0px 10px 5px 0px', display: 'inline-block'}}>Security Hash Algorithm:</FormLabel>
                    <RadioGroup aria-labelledby="securityHashAlgorithm" value={values.securityHashAlgorithm} onChange={handleSecurityHashAlgorithm} style={{ alignItems: 'baseline' }}>
                        <FormControlLabel value={'RSA_SHA1'} control={<Radio disabled={(dataLoading || !disableInput)} color="primary" 
                        />} label="SHA-1" />
                        <FormControlLabel value={'RSA_SHA256'} control={<Radio disabled={(dataLoading || !disableInput)} color="primary" 
                        />} label="SHA-256"/>
                    </RadioGroup>
                    <br/>
                    <br/>
                    <FormLabel id="identityLocation" style={{padding: '0px 10px 5px 0px', display: 'inline-block'}}>SAML Identity Location:</FormLabel>
                    <RadioGroup aria-labelledby="identityLocation" value={values.identityLocation} onChange={handleIdentityLocation} style={{ alignItems: 'baseline' }}>
                        <FormControlLabel value={'inNameID'} control={<Radio disabled={(dataLoading || !disableInput)} color="primary" 
                        />} label="Identity is in the NameIdentifier element of the Subject statement" />
                        <FormControlLabel value={'inAttribute'} control={<Radio disabled={(dataLoading || !disableInput)} color="primary" 
                        />} label="Identity is in an Attribute element"/>
                    </RadioGroup>
                    <br/>
                    <br/>
                    <div style={{display: 'inline-block'}}>
                        <Button
                            style={{ margin: 'auto', marginRight: '2rem', minWidth: '8rem' }}
                            type="submit"
                            color="primary"
                            disabled={(dataLoading || !disableInput)}
                            variant="contained">Save</Button>
                        {(currentSsoCredentials.apiName !== undefined || success) &&
                        <Button
                            style={{ margin: 'auto', minWidth: '8rem' }}
                            type="clear"
                            color="secondary"
                            disabled={(dataLoading || !disableInput)}
                            onClick={handleDelete}
                            variant="contained">Clear</Button>
                        }
                    </div>
                </Grid>
            </Grid>
            {submitStatus === 'success' && 
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 580 }}>
                    <CheckCircleOutlineIcon style={{ color: '#1aa68f', marginRight: 10, fontSize: 30 }}/>
                    <div style={{ color: 'rgb(97, 95, 87)' }}>SUCCESS! Your SSO configuration was saved.</div>
                </div>
            }
            {submitStatus === 'failure' && 
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', margin: '20px auto 0', maxWidth: 715 }}>
                <ErrorOutlineIcon style={{ color: '#f4473a', marginRight: 10, fontSize: 30 }}/>
                <div style={{ color: '#f4473a' }}>{errorMessage ? errorMessage : "ERROR! We are not able to save your configuration at this time. Please try again later."}</div>
            </div>
            }
        </form>
    )
}

function SingleSignOnSettingsComponent(props) {
    const { getSsoCredentials, deleteSsoCredentials, currentSsoCredentials, disableInput } = props;
    const [submitStatus, setSubmitStatus] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [dataLoading, setDataLoading] = useState(false);
    const [success, setSuccess] = useState(false)
    const [formValues, setFormValues] = useState(null);

    const validationSchema = Yup.object({
        apiName: Yup.string("Enter a valid API Name")
                .trim()
                .required("API Name is a required field.")
                .max(50, "API Name has a max limit of 50 characters."),
        fileName: formValues && formValues.fileName.length > 0 ? 
                    Yup.string()
                        .required()
                        .matches(/.+(\.xml)$/, 'File type must be XML.') :
                    Yup.string()
                        .required('No file provided.'),
        identityProviderLoginUrl: Yup.string("Enter a valid URL")
                .trim()
                .max(255, "Identity Provider Login URL has a max limit of 255 characters.")
                .matches(
                    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    'Enter valid URL'
                ),
    })

    return (
        <Formik
            initialValues={{ 
                apiName: '',
                file: null,
                fileName: '',
                securityHashAlgorithm: 'RSA_SHA256',
                identityLocation: 'inNameID'
            }}
            validationSchema={validationSchema}
            onSubmit = {
                (values, { resetForm, setErrors }) => {
                    const ssoMetaData = {
                        apiName: values.apiName,
                        companyId: props.company.id ? props.company.id : null,
                        securityHashAlgorithm: values.securityHashAlgorithm,
                        identityLocation: values.identityLocation,
                        bindingType: 'httpRedirect'
                    };

                    const payload = new FormData();
                    payload.append("file", values.file);
                    payload.append("ssoMetaData", JSON.stringify(ssoMetaData));

                    setDataLoading(true);
                    SingleSignOnApi.addSsoSettings(payload)
                    .then(response => {
                        setDataLoading(false);
                        setSubmitStatus('success');
                        setSuccess(true);
                        // resetForm();
                    })
                    .catch(error => {
                        setDataLoading(false);
                        setSubmitStatus('failure');

                        if (error.response && error.response.data && error.response.data.detail) {
                            console.log(error.response.data.detail);
                            setErrorMessage(error.response.data.detail)
                        } else {
                            console.log(error);
                        }
                    });
                }
            }
            render={formikProps => <SingleSignOnSettings 
                disableInput={disableInput}
                key={currentSsoCredentials} 
                setFormValues={setFormValues}
                submitStatus={submitStatus}
                errorMessage={errorMessage}
                dataLoading={dataLoading}
                props={props}
                getSsoCredentials={getSsoCredentials}
                deleteSsoCredentials={deleteSsoCredentials}
                currentSsoCredentials={currentSsoCredentials}
                success={success}
                setSuccess={setSuccess}
                setSubmitStatus={setSubmitStatus}
                setErrorMessage={setErrorMessage}
                {...formikProps}
                />}
        />
    );
}

const mapStateToProps = () => createStructuredSelector({
	currentSsoCredentials: makeSelectCompanySsoSettings(),
	ssoLoading: makeSelectSsoLoading()
});

const mapDispatchToProps = dispatch => {
	return {
        getSsoCredentials: (companyId) => dispatch(OrganizationManagementAction.getSsoCredentials(companyId)),
        deleteSsoCredentials: (companyId) => dispatch(OrganizationManagementAction.deleteSsoCredentials(companyId)),
	}
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
)(SingleSignOnSettingsComponent);