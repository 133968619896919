import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import HasAccess from "../../components/HasAccess/HasAccess";

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import searchFieldRegex  from '../../utils/searchFieldRegex';
import SearchField  from '../../utils/searchField';

import BssidListTable from '../../containers/BssidListTable/bssidListTable';

import { actions as BssidActions, makeSelectBssidList, makeSelectBssidListLoading } from '../../reducers/networkDiscovery';

import { types as bssidTypes } from '../../reducers/networkDiscovery';
import { actions as AppActions } from '../../reducers/app';
import { debounce } from 'lodash';
import Switch from '@material-ui/core/Switch';
import {InputLabel} from '@material-ui/core'


const initialSortColumns = [
    {
        id: 'location',
        desc:false
    }
]

function BssidList(props) {
    const [page, setPage] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [sortColumns, setSortColumns] = useState(initialSortColumns);
    const [currentSortColumn, setCurrentSortColumn] = useState(initialSortColumns[0]);
    const [pageSize, setPageSize] = useState(25);
    const [pageIndex, setPageIndex] = useState(0);
    const [unmapped, setUnmapped] = useState(false);
    

    function handleInputSearchChange(e) {
        const inputValue = e.target.value;
        const isMatch = searchFieldRegex(inputValue);

        if (isMatch) {
            setSearchValue(inputValue);
            searchApiCall(
                inputValue, 
                pageSize, 
                currentSortColumn.id, 
                currentSortColumn.desc ? 'DESC': 'ASC',
                unmapped
            );
        }
    }

    const searchApiCall = useCallback(
        debounce((inputValue, pageSize, sortBy, direction, unmapped) => {
            if (inputValue.length > 0) {
                setPage(0);
                props.fetchBssidList(1, pageSize, inputValue, sortBy, direction, unmapped);
            } else if(inputValue.length === 0){
                setPage(0);
                props.fetchBssidList(1, pageSize, '', sortBy, direction, unmapped);
            }
        }, 500)
    , []);

    
    function modalHandleOpen() {
        props.setCurrentModal(bssidTypes.ADD_BSSID);
    }

    function handleUnmapped(){
        props.fetchBssidList(1, pageSize, '', currentSortColumn.id, currentSortColumn.desc ? 'DESC': 'ASC', !unmapped);
        setUnmapped(!unmapped);
    }   

        return (
            <div>
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item>
                        <SearchField 
                            value={searchValue}
                            onChange={handleInputSearchChange}
                        />
                    </Grid>
                    <Grid item style={{ marginLeft: '20px', marginRight: 'auto' }}>
                    <InputLabel htmlFor="unmapped" shrink={false}>Unmapped</InputLabel>
                    <Switch
                        id="unmapped"
                        name="unmapped"
                        color="primary"
                        checked={props.unmapped}
                        onChange={handleUnmapped}/>
                    </Grid>
                    <Grid item>
                        <HasAccess
                            permissions={["ADD_NETWORK_DISCOVERY"]}
                        >
                            {   searchValue.length === 0 &&
                            <Button className="button" id="btnAddBuilding" variant="contained" color="primary" onClick={modalHandleOpen}>
                                Add BSSID Mapping
                            </Button> 
                            }
                        </HasAccess>
                    </Grid>
                </Grid>
                <br />
                <BssidListTable 
                    bssidList={props.bssidList} 
                    setCurrentModal={props.setCurrentModal} 
                    loading={props.loading} 
                    setPage={setPage}
                    page={page}
                    sortColumns={sortColumns}
                    setSortColumns={setSortColumns}
                    pageIndex={pageIndex}
                    setPageIndex={setPageIndex}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    currentSortColumn={currentSortColumn}
                    setCurrentSortColumn={setCurrentSortColumn}
                    searchValue={searchValue}
                    unmapped={unmapped}
                    />
            </div>    
        );
}

BssidList.propTypes = {
    setCurrentModal: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
    bssidList: makeSelectBssidList(),
    loading: makeSelectBssidListLoading()
});

const mapDispatchToProps = dispatch => {
    return {
        fetchBssidList: (companyId, page, pageSize, searchTerm, sortBy, direction, unmapped) => dispatch(BssidActions.getBssidRequest(companyId, page, pageSize, searchTerm, sortBy, direction, unmapped)),
        setCurrentModal: (currentModal, data) => dispatch(AppActions.setCurrentModal(currentModal, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BssidList);
